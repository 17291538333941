import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanmortoni/Code/Personal/qvsr/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "voting-methods"
    }}>{`Voting methods`}</h1>
    <h2 {...{
      "id": "existing-methods"
    }}>{`Existing methods`}</h2>
    <p>{`QVSR currently supports 2 voting methods, Linear and Quadratic.`}</p>
    <ul>
      <li parentName="ul">{`Linear is 1 credit per vote.`}</li>
      <li parentName="ul">{`Quadratic is y = x², where x is the number of votes.`}</li>
    </ul>
    <div>
  <table>
    <tr>
      <th>Votes</th>
      <th>Total Cost</th>
      <th>Marginal Cost</th>
    </tr>
    <tr>
      <td>1</td>
      <td>1</td>
      <td>1</td>
    </tr>
    <tr>
      <td>2</td>
      <td>4</td>
      <td>3</td>
    </tr>
    <tr>
      <td>3</td>
      <td>9</td>
      <td>5</td>
    </tr>
    <tr>
      <td>4</td>
      <td>16</td>
      <td>7</td>
    </tr>
    <tr>
      <td>5</td>
      <td>25</td>
      <td>9</td>
    </tr>
    <caption>Quadratic voting example</caption>
  </table>
    </div>
    <h2 {...{
      "id": "create-your-method"
    }}>{`Create your method`}</h2>
    <p>{`To create new voting methods you can follow this:`}</p>
    <p><inlineCode parentName="p">{`src/components/Survey/methods`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`// survey object is expected as param
const NewVotingMethod = (survey) => {
  const { credits, preferredFunction } = survey.config
  const [availableCredits, setAvailableCredits] = React.useState(parseInt(credits))
  const [questions, setQuestions] = React.useState([])

  React.useEffect(() => {
    const newQuestions = survey.setup.questions
      .map((question, index) => ({
        label: question.value,
        vote: 0,
        id: question.id,
      }))
      .sort(() => 0.5 - Math.random())

    setQuestions(newQuestions)
  }, [preferredFunction, survey])

  React.useEffect(() => {
    if (questions) {
      const totalCost = questions.reduce((cost, q, i) => (q.vote < 0 ? cost + q.vote * -1 : cost + q.vote), 0)

      setAvailableCredits(credits - totalCost)
    }
  }, [questions, credits])

  function canVote(index, number) {...}

  function vote(index, number) {...}

  return { availableCredits, questions, vote, canVote }
}
`}</code></pre>
    <p>{`We expect that your method API returns a `}<inlineCode parentName="p">{`canVote`}</inlineCode>{`, `}<inlineCode parentName="p">{`vote`}</inlineCode>{` functions. `}<inlineCode parentName="p">{`availableCredits`}</inlineCode>{` variable and `}<inlineCode parentName="p">{`questions`}</inlineCode>{` with the following structure:`}</p>
    <pre><code parentName="pre" {...{}}>{`question = {
  label: 'question example',
  vote: 0,
  id: 0, // question.id
}
`}</code></pre>
    <p>{`And set the new method on index:`}</p>
    <pre><code parentName="pre" {...{}}>{`import Quadratic from './quadratic'
import Linear from './linear'

const useMethod = (survey) => {
  const { preferredFunction } = survey.config
  const functions = {
    Quadratic,
    Linear,
    NewVotingMethod,
  }

  return functions[preferredFunction](survey)
}

export default useMethod
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      